export const FETCH_ORGANISATION_BY_ID = 'digitalStore/organisationDetails/FETCH_ORGANISATION_BY_ID'
export const FETCH_ORGANISATION_RECEIVERS = 'digitalStore/organisationDetails/FETCH_ORGANISATION_RECEIVERS'
export const UPDATE_ORGANISATION = 'digitalStore/organisationDetails/UPDATE_ORGANISATION'
export const CREATE_ORGANISATION = 'digitalStore/organisationDetails/CREATE_ORGANISATION'
export const RESET_STORE = 'digitalStore/organisationDetails/RESET_STORE'
export const CREATE_DRUGS_LISTS = 'digitalStore/organisationDetails/CREATE_DRUGS_LISTS'
export const EDIT_ORGANISATION_FORM_NAME = 'organisation-details-form'
export const NEW_ORGANISATION_FORM_NAME = 'organisation-new-details-form'
export const ORGANISATION_DRUGS_FORM_NAME = 'organisation-drugs-form'
export const ORGANISATION_PROFESSIONS_FORM_NAME = 'organisation-professions-form'
export const ORGANISATION_PROFESSION_GROUPS_FORM_NAME = 'organisation-profession-groups-form'
export const SET_PROFESSIONS_FOR_ORGANISATION = 'digitalStore/organisationDetails/SET_PROFESSIONS_FOR_ORGANISATION'
export const SET_PROFESSION_GROUPS_FOR_ORGANISATION = 'digitalStore/organisationDetails/SET_PROFESSION_GROUPS_FOR_ORGANISATION'
export const SET_LOCATIONS_FOR_ORGANISATION = 'digitalStore/organisationDetails/SET_LOCATIONS_FOR_ORGANISATION'
export const IMPORT_DRUG_META = 'digitalStore/organisationDetails/IMPORT_DRUG_META'
export const SSO_CONFIGURATION = 'digitalStore/organisationDetails/SSO_CONFIGURATION'
export const ORGANISATION_LOCATIONS_FORM_NAME = 'organisation-locations-form'
export const REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME = 'reporting-organisation-contact-details'
export const NEW_REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME = 'company-account-request'
export const REPORT_RECEIVER_FORM_NAME = 'repeatableform'
export const reportsToBeAnonymised = [
  'Standard E2B ICH R2 medicines form',
  'Standard E2B ICH R3 medicines form'
]
