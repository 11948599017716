import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import { FormBody, FormError } from '../../../components/Form'
import FormContentBox from '../../../components/FormContentBox'
import { translations } from '../../../config'
import styles from './style'
import * as schema from './schema'
import FullScreenProgress from '../../../components/FullScreenLoader'
import Editable from '../../../components/Editable'
import RolesAndOrganisationsForm from '../UserForm/RolesOrganisationsProfessions'
import WrappedPasswordForm from './Password'

import ActionBar from '../../../components/ActionBar'
import TopRightButton from '../../../components/TopRightButton'
import { isCurrentUserAbleToViewRoleAndOrganisations } from '../../../store/modules/combinedSelectors/authCombinedSelectors'
import ChangeRequest from './ChangeRequest'
import { Can, useAbilities } from '../../../ability/ability-context'
import OrganisationPreferences from './OrganisationPreferences'
import ReportSummary from './ReportSummary'
import UserOrganisations from './UserOrganisations'
import { useEffect } from 'react'
import moment from 'moment'
import DeletionModal from '../DeletionModal/DeletionModal'
import MyProfileMoreMenu from './MyProfileMoreMenu'
import { getSabreSchema } from '../UserRegistrationScreen/SabreUserRegistrationForm/SabreUserRegistrationForm'
import CommunicationPreferences from './CommunicationPreferences'
import UserGroupsForm from './UserGroups'

const PersonalDetailsForm = Editable(FormContentBox('personal-details'))
const PermissionsPolicyForm = Editable(FormContentBox('permissions-policy'))

const ViewUserFormWrapper = (props) => {
  const {
    classes,
    initialValues = {
      firstName: '',
      lastName: ''
    },
    onSubmit,
    organisationOptions,
    isPersonalDetailsEditable,
    isRolesAndOrganisationsEditable,
    isProfessionEditable,
    isPasswordEditable,
    isSelectedUserDeactivated,
    isMarkedForDeletion,
    isSelectedUserLocked,
    isCurrentUserAbleToDeactivateUser,
    canManageUsers,
    showDeactivateModal,
    openModal,
    getPageTitle,
    isCurrentUserSelectedUser,
    isPublic,
    canUserModifyPermissions,
    isCurrentUserAndSelectedUserOrgLead,
    currentUser,
    organisationDetails,
    pendingChangeRequests,
    isMoreRegistrationEnabled,
    isSabreRegistrationEnabled,
    hasReportAuditEnabled,
    hideProfessions,
    hidePermissions,
    hideReportSummary,
    hideOrganisations,
    hideOrganisationPreferences,
    hideCommunicationPreferences,
    isPermissionsEditorEnabled,
    canViewPermissions,
    canEditPermissions
  } = props

  const userFullName = initialValues.firstName || initialValues.lastName
    ? `${initialValues.firstName} ${initialValues.lastName}`
    : 'Complete your profile'
  const rolesAndOrganisationsKeys = ['roleId', 'organisationIds', 'professionId', 'accountType']
  const rolesAndOrganisationsInitialValues = _.pick(initialValues, _.concat(rolesAndOrganisationsKeys, 'id'))
  const personalDetailsInitialValues = _.omit(initialValues, rolesAndOrganisationsKeys)
  const passwordInitialValues = _.pick(initialValues, 'id')
  const preferencesInitialValues = _.pick(initialValues, ['id', 'emailCommunicationsPermitted', 'smsCommunicationsPermitted', 'telephone', 'details'])
  const userPreferencesInitialValues = {
    ...preferencesInitialValues, 
    isWeeklyTransmissionsReportsEnabled: preferencesInitialValues?.details?.isWeeklyTransmissionsReportsEnabled
  }
  /**
   * The currently signed in user is in user management, editing or viewing another user.
   * As opposed to editing their own profile.
   */
  const isViewingOrEditingAnotherUser = !isCurrentUserSelectedUser
  /**
   * The currently signed in user is in user management viewing themself or has gone to view my profile.
   */
  const isViewingOrEditingTheirOwnUser = isCurrentUserSelectedUser
  const ability = useAbilities()
  /**
   * The bare minimum object to recreate a user who has updated policies in the `onSubmit`.
   * The Data is reshaped from a  intialValues object in to something for the API.
   *
   * `isPolicyForm` is for the `onSubmit` to know the data has come directly from the
   * permissions form instead of the another form that appears on the edit user screen.
   */
  const permissionsInitialValues = {
    id: _.get(initialValues, 'id'),
    isPolicyForm: true,
    ..._.get(initialValues, 'policies', {})
  }

  const pageTitle = getPageTitle(userFullName)
  const isSSOUser = _.get(currentUser, 'loginMethod') === 'sso'

  const [personalDetailsSchema, setPersonalDetailsSchema] = useState()
  const canEditUserEmail = isCurrentUserSelectedUser || ability.can('editUserEmail', 'userManagement')
  
  useEffect(() => {
    setPersonalDetailsSchema(schema.personal({ allFieldsMandatory: isMoreRegistrationEnabled, canEditUserEmail }))
    if (isSabreRegistrationEnabled) {
      setPersonalDetailsSchema(getSabreSchema({ isOrganisationInvite: false }))
    }
  }, [isMoreRegistrationEnabled, isSabreRegistrationEnabled, canEditUserEmail])

  const personalDetailsFormSchema = _.map(personalDetailsSchema, (scheme) => {
    if (isSSOUser && _.get(scheme, 'id') === 'email') {
      return { ...scheme, props: { ...scheme.props, disabled: true } }
    }
    return scheme
  })

  const abilityCanEditUserOrg = ability.can('changeUserOrganisations', 'userManagement')
  const abilityCanEditUserRole = ability.can('changeUserRoles', 'userManagement')
  const canEditUserPersonalDetails = ability.can('editUserPersonalDetails', 'userManagement') && isPersonalDetailsEditable
  const canEditUserOrganisationOrRole = (abilityCanEditUserOrg || abilityCanEditUserRole) && (isRolesAndOrganisationsEditable || (isProfessionEditable && !isCurrentUserAbleToViewRoleAndOrganisations) || isPublic)
  /**
   * @description Has the currently signed in user have the right to view another user's "Organisation Preferences" Section?
   *
   * True if they're an org lead or higher on most platforms, or a user is viewing their own profile.
   *
   * At the time of writing, In the MORE platform, Org leads can not see the section.
   */
  const canViewOtherUsersOrganisations = ability.can('canViewOtherUsersOrganisations', 'userManagement') || isViewingOrEditingTheirOwnUser
  /**
   * @description Has the currently signed in user have the right to view another user's "Organisation Preferences" Section?
   *
   * True if they're an org lead or higher on most platforms, or a user is viewing their own profile.
   *
   * At the time of writing, In the MORE platform, Org leads can not see the section.
   */
  const canViewOtherUsersOrganisationPreferences = ability.can('canViewOtherUsersOrganisationPreferences', 'userManagement') || isViewingOrEditingTheirOwnUser

  const shouldRenderLockedAccount = isSelectedUserLocked && !isSelectedUserDeactivated
  const shouldRenderReactivateButton = !isMarkedForDeletion && isSelectedUserDeactivated && isCurrentUserAbleToDeactivateUser
  const shouldRenderDeactivateButton = !isSelectedUserDeactivated && isCurrentUserAbleToDeactivateUser
  const shouldRenderReportSummary = !hideReportSummary
  const shouldRenderOrganisations = canViewOtherUsersOrganisations && !hideOrganisations
  const shouldRenderPermissions = !hidePermissions && canManageUsers && canUserModifyPermissions
  const shouldRenderCommunicationPreferences = !hideCommunicationPreferences
  const shouldRenderOrganisationPreferences = canViewOtherUsersOrganisationPreferences && !hideOrganisationPreferences
  const shouldRenderPasswordForm = isCurrentUserSelectedUser && !isSSOUser
  const shouldRenderUserGroups = isPermissionsEditorEnabled && canViewPermissions && canEditPermissions
  const permissionSchema = schema.permissions({ hasReportAuditEnabled, ability, isCurrentUserAndSelectedUserOrgLead }).schema
 
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component={'h1'} uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
        rightContent={
          isViewingOrEditingTheirOwnUser && (
            <MyProfileMoreMenu />
          )
        }
      />
      {
        isMarkedForDeletion && (
          <div>
            <ActionBar
              title={translations('User Deletion - Timestamp', {
                timestamp: moment(initialValues.accountDeletionRequestedAt).format('DD/MM/YYYY HH:mm')
              })}
              buttonTitle={translations('User Deletion - Delete Account')}
              onClick={() => openModal(() => <DeletionModal initialValues={initialValues} />)}
              action='delete'
            />
            <P value={translations('required fields')} className={classes.requiredTextDeactivate} />
          </div>
        )
      }
      {
        shouldRenderReactivateButton && (
          <div>
            <ActionBar
              title={translations('Account Deactivated')}
              buttonTitle={translations('Re-Activate')}
              onClick={() => showDeactivateModal()}
            />
            <P value={translations('required fields')} className={classes.requiredTextDeactivate} />
          </div>
        )
      }
      {
        shouldRenderLockedAccount && (
          <ActionBar
            title={translations('Account Locked')}
            hideButton
          />
        )
      }
      <div className={classes.contentContainer}>
        <PendingChangeRequests pendingChangeRequests={pendingChangeRequests} />
        {
          shouldRenderDeactivateButton && (
            <DeactivateButton
              classes={classes}
              showDeactivateModal={showDeactivateModal}
            />
          )
        }
        <PersonalDetailsForm
          editable={canEditUserPersonalDetails}
          enableReinitialize
          initialValues={personalDetailsInitialValues}
          givenClass={classes.formContainer}
          onSubmit={onSubmit}
          boxName={translations('Personal Details')}
          formId='personal-details' >
          <FormError givenClass={classes.submitError} />
          <FormBody
            schema={personalDetailsFormSchema}
            layout={schema.personal().layout}
            globalClasses={{
              col: classes.columnStyle,
              row: classes.rowStyle
            }}
          />
        </PersonalDetailsForm>
        {
          shouldRenderReportSummary && (
            <ReportSummary />
          )
        }
        {
          shouldRenderOrganisations && (
            <UserOrganisations
              classes={classes}
              editable={canEditUserOrganisationOrRole}
            />
          )
        }
        {
          (
            <RolesAndOrganisationsForm
              classes={classes}
              organisationOptions={organisationOptions}
              hideOrganisations={true}
              hideProfessions={hideProfessions}
              enableReinitialize
              initialValues={rolesAndOrganisationsInitialValues}
              givenClass={classes.formContainer}
              formId='roles-and-organisations'
              editable={canEditUserOrganisationOrRole || isCurrentUserSelectedUser}
              isPublic={isPublic}
              onSubmit={onSubmit}
            />
          )
        }
        {
          shouldRenderUserGroups && (
            <UserGroupsForm
              editing={true}
            />
          )
        }
        {
          shouldRenderPermissions && _.size(permissionSchema) > 0 && (
            <Can I='editUserPermissions' a='userManagement'>
              <PermissionsPolicyForm
                editable={true}
                enableReinitialize
                initialValues={permissionsInitialValues}
                givenClass={classes.formContainer}
                onSubmit={onSubmit}
                boxName={translations('Permissions Title', { organisationName: organisationDetails.senderorganization })}
                formId='permissions-policy' >
                <FormError givenClass={classes.submitError} />
                <FormBody
                  schema={permissionSchema}
                  layout={schema.permissions().layout}
                  globalClasses={{
                    col: classes.columnStyle,
                    row: classes.rowStyle
                  }}
                />
              </PermissionsPolicyForm>
            </Can>
          )
        }
        {
          shouldRenderCommunicationPreferences && (
            <CommunicationPreferences
              classes={classes}
              editable={isPersonalDetailsEditable}
              initialValues={userPreferencesInitialValues}
              onSubmit={onSubmit}
              canManageUsers={canManageUsers}
              isViewingOrEditingAnotherUser={isViewingOrEditingAnotherUser}
            />
          )
        }
        {
          shouldRenderOrganisationPreferences && (
            <OrganisationPreferences
              classes={classes}
              editable={isPersonalDetailsEditable}
              onSubmit={onSubmit}
            />
          )
        }
        {
          shouldRenderPasswordForm && (
            <WrappedPasswordForm
              classes={classes}
              editable={isPasswordEditable}
              boxName={translations('Password')}
              formId='password'
              onSubmit={onSubmit}
              initialValues={passwordInitialValues}
            />
          )
        }
      </div>
    </div>
  )
}

const PendingChangeRequests = ({
  pendingChangeRequests = []
}) => {
  return _.map(pendingChangeRequests, changeRequest => (
    <ChangeRequest changeRequest={changeRequest} />
  ))
}

const DeactivateButton = ({
  classes,
  showDeactivateModal
}) => {
  return (
    <div className={classes.requiredDeactivateButtonContainer}>
      <P value={translations('required fields')} className={classes.requiredText} />
      <TopRightButton
        onClick={showDeactivateModal}
      >
        {translations('De-Activate')}
      </TopRightButton>
    </div>
  )
}

ViewUserFormWrapper.defaultProps = {
  isPublic: false
}

ViewUserFormWrapper.propTypes = {
  isPublic: PropTypes.bool,
  getPageTitle: PropTypes.func.isRequired,
  isCurrentUserSelectedUser: PropTypes.bool
}

export default FullScreenProgress(
  withStyles(styles)(ViewUserFormWrapper)
)
