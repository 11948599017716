import React, { useMemo } from 'react'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import Button from '../../../../../components/Button'
import Types from '../Table/types'

export const ChangeList = styled('div')({
  display: 'flex',
  'flex-direction': 'column',
  gap: '10px'
})

export const Modification = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.grey['100'],
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap'
}))

export const AdditionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  'flex-direction': 'column',
  gap: '5px',
  'background-color': theme.palette.grey['100'],
  padding: '10px',
  'border-radius': '5px'
}))

export const Addition = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.success.light,
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap'
}))

export const NewKey = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.success.light,
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap',
  margin: 0
}))

const ActionContainer = styled('div')({
  'margin-top': '20px',
  display: 'flex',
  gap: '10px'
})

const CloseButton = styled(Button)({
  'margin-left': 'auto'
})

export const Changes = ({ additions, modifications, newKeys, changes }) => {
  const hasAdditions = !!Object.keys(additions).length
  const hasModifications = !!modifications.length
  const hasNewKeys = !!newKeys.length

  return <>
    {hasModifications && (
      <ChangeList>
        <h3>{translations('Manage Translations - Save Changes Modifications Header')}</h3>
        {changes.map(({ type, ...modification }, index) => {
          switch (type) {
            case Types.TRANSLATION: {
              const { value, key, initial, languageName } = modification

              return (
                <Modification key={index}>{translations('Manage Translations - Save Changes TRANSLATION', { languageName, key, initial, value })}</Modification>
              )
            }
            case Types.KEY: {
              const { value, initial } = modification

              return (
                <Modification key={index}>{translations('Manage Translations - Save Changes KEY', { initial, value })}</Modification>
              )
            }
            default:
              return null
          }
        })}
      </ChangeList>
    )}
    {hasAdditions && (
      <ChangeList>
        <h3>{translations('Manage Translations - Save Changes Additions Header')}</h3>
        {Object.entries(additions).map(([key, langs]) => (
          <AdditionContainer key={key}>
            <h4>{key}</h4>
            {_.map(langs, ({ languageName, value }, index) => (
              <Addition key={index}>{translations('Manage Translations - Save Changes NEW_TRANSLATION', { languageName, value })}</Addition>
            ))}
          </AdditionContainer>
        ))}
      </ChangeList>
    )}
    {hasNewKeys && (
      <ChangeList>
        <h3>{translations('Manage Translations - Save Changes New Keys Header')}</h3>
        {newKeys.map(({ value }, index) => (
          <NewKey key={index}>{value}</NewKey>
        ))}
      </ChangeList>
    )}
  </>
}

const SaveChangesModal = ({ changes, onSubmitTranslationChanges, onClose }) => {
  const { additions, modifications, newKeys } = useMemo(() => {
    const additions = _.groupBy(changes.filter(({ type }) => type === Types.NEW_TRANSLATION), 'key')
    const modifications = changes.filter(({ type }) => [Types.TRANSLATION, Types.KEY].includes(type))
    const newKeys = changes.filter(({ type }) => type === Types.NEW_KEY)

    return { additions, modifications, newKeys }
  }, [changes])

  return (
    <BasicModalOverlay title="Confirm modifications & additions">
      <Changes additions={additions} modifications={modifications} newKeys={newKeys} changes={changes} />
      <ActionContainer>
        <CloseButton onClick={() => onClose()} raised color="primary">{translations('Manage Translations - Save Changes Close Button')}</CloseButton>
        <Button onClick={() => onSubmitTranslationChanges(changes)} raised color="primary">{translations('Manage Translations - Save Changes Button')}</Button>
      </ActionContainer>
    </BasicModalOverlay>
  )
}

export default SaveChangesModal
